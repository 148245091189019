import React from "react"
import Layout from "@components/layout"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"

// コンポーネント呼び出し
import { FaqGraphqlArray } from "@components/graphql-data-faq.js"; // 記事情報取得・形成
import { FixedFoot } from "@components/footer";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定
import { WordSearch } from "@components/word-search.js";

const PropFaq = () => {
  const indexUrl = '/';
  const typeUrl = 'faq#how';
  const pageTitle = 'お客様サポート';
  const pageSubTitle = 'FAQ';
  const LinkBeginnerL = indexUrl + typeUrl;
  const LinkBeginnerR = indexUrl + "contact#how";
  const wordSearchContent = WordSearch(indexUrl, typeUrl).Faq; // ワード検索Faq

  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
  
  // graphql-data-post.jsから記事データ呼び出し
  const allPostData = FaqGraphqlArray( '', 'allmix');
  
  // 記事一覧生成設定
  let setArray, selectArray;
  setArray = allPostData.mainCateg; // 該当カテゴリ配列情報
  
  let faqArray = [];
  setArray.map((result) => {
    selectArray = FaqGraphqlArray( result, 'setCateg').checkCateg;
    if ( selectArray.length !== 0 ) {
      faqArray.push(selectArray)
    }
  });
  
  const pickupList = faqArray.map((result) => {

    let list = result.map((subResult) => {
      return  <>
      <li className="p-3 border-b-[#0054BF] border-solid border-b-[1px] text-[#0054BF]">
        <a href={indexUrl + typeUrl + subResult.Slug + "/"}>{subResult.Title}</a>
      </li>
      </>
    });

    return  <>
              <div className="md:w-[48%] w-full mb-10 border-[1px] rounded-xl mx-[1%] bg-white" key="" >
                <article className="post-list-item !m-0 h-[100%]"  itemScope itemType="http://schema.org/Article">
                  <div className="w-[100%] h-[100%]">
                   
                    {/* タイトル部分 */}
                    <div className="w-full text-left h-[20%] px-3 py-2 rounded-tl-xl rounded-tr-xl bg-[#0054BF]">
                      <a href={indexUrl + typeUrl + result[0].CategsSlug + "/"}>
                        <p className="CampP md:text-[15px] text-[15px] mb-4 py-2 text-left text-white" >{result[0].Categories[0]}</p>
                      </a>
                    </div>
                    
                    {/* list */}
                    <div className="p-5">
                      {list}
                    </div>
                  </div>
                </article>
              </div>
            </> ;
  });


  // Seo情報設定
  const pathName = 'faq';
  const seoTag = SeoContents({ Path:pathName });

  return (
    <> 
      <Layout>
        {seoTag}
        <div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
          <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
            <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
              <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">{pageTitle}</h2>
              <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">{pageSubTitle}</h3>
            </div>
          </div>
        </div>
        
        {/* サブメニュー */}
        <div className="bg-[#F6F6F6]">
        <a id="how" className="jump_point"></a>
          <div className="max-w-5xl mx-auto flex px-5">
            <a href={LinkBeginnerL} className="md:text-base text-xs text-center py-4 px-2 md:px-10 w-1/2 bg-white mr-3 font-semibold border-b-[15px] border-white text-[#1F55A6] ">
              よくある質問
            </a>
            <a href={LinkBeginnerR} className="md:text-base text-xs  text-center py-4 px-2 md:px-10 w-1/2 bg-white ml-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">
              お問い合わせ
            </a>
          </div>
        </div>

        {/* 検索窓 */}
        <div className="max-w-xl mx-auto md:max-w-5xl mt-[30px] px-5">
          <div className="h-20">
						{wordSearchContent}
					</div>
        </div>
        
        {/* 記事アーカイブ */}
        <div className="bg-[#F6F6F6] pt-[70px]">
          <div className="max-w-xl mx-auto md:max-w-5xl px-5">
            <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6]">FAQカテゴリ一覧</h3>
            <div className="flex flex-wrap justify-between items-start">
              {pickupList}
            </div>
          </div>  
        </div>
        
        {/* 固定フッター */}
        {fixedItem}
      </Layout>
    </>
  )
}
export default PropFaq
